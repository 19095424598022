import React, { useEffect } from 'react'
import { motion } from 'motion/react'
import { Link, useLocation } from 'react-router-dom'
import CardFeatures from './card/CardFeatures'
import featuresData from '../data/FeaturesData.json'
import * as Icons from 'react-icons/fa'
import { BsFillChatLeftTextFill } from 'react-icons/bs'
import { renderToStaticMarkup } from 'react-dom/server'

const FeaturesPanel = () => {
	const location = useLocation()

	useEffect(() => {
		if (location.state && location.state.scrollPosition) {
			window.scrollTo(0, location.state.scrollPosition)
		}
	}, [location])

	const convertIconToDataURL = (IconComponent) => {
		const svgString = renderToStaticMarkup(
			<IconComponent style={{ fill: '#0080fe' }} />
		)
		const encodedSvgString = encodeURIComponent(svgString)
		return `data:image/svg+xml,${encodedSvgString}`
	}

	const handleLinkClick = (featureId) => {
		const scrollPosition = window.scrollY
		return {
			pathname: '/feature',
			state: { featureId, scrollPosition },
		}
	}

	return (
		<div className="w-full px-4 py-2 mb-16">
			<div>
				<div className="mb-6">
					<div className="max-w-[1200px] mx-auto text-center flex flex-col justify-center">
						<h1 className="md:text-4xl sm:text-3xl text-2xl p-2 text-[#074f9b]">
							A Winning Combination of{' '}
							<span className="colorMDTText">
								Collaboration
							</span>{' '}
							and{' '}
							<span className="colorMDTText">
								Site Visibility
							</span>
							.
						</h1>
					</div>
				</div>

				<div>
					<div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-16">
						{featuresData.Features.map((feature) => {
							const IconComponent = feature.featureIcon
								? Icons[feature.featureIcon]
								: BsFillChatLeftTextFill
							const iconUrl =
								convertIconToDataURL(IconComponent)

							return (
								<Link
									key={feature._id}
									to={handleLinkClick(feature._id)}
								>
									<motion.div
										initial={{
											scale: 0.9,
											opacity: 0,
										}}
										whileInView={{
											opacity: 1,
											scale: 1,
											transition: {
												duration: 1.5,
											},
										}}
										viewport={{
											once: true,
											amount: 0.1,
										}}
									>
										<CardFeatures
											cardImage={iconUrl}
											cardBanner={
												feature.banner
											}
											cardLink={`/feature#${feature._id}`}
										/>
									</motion.div>
								</Link>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FeaturesPanel
