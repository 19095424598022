import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import FeaturesDirect from '../components/FeaturesDirect'

const FeaturesPanelWrap = () => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	const preSpanText = 'Streamlining Deal Teams for'
	const spanText = 'Real Estate '
	const postSpanText = 'Excellence.'

	return (
		<Layout>
			<div
				id="wrapHeader"
				className={`w-full z-10 ${animate ? 'fade-in' : ''}`}
			>
				<div className="w-full ">
					<div className="max-w-[1240px] mx-auto text-center flex flex-col justify-center">
						<h1 className="md:text-4xl sm:text-3xl text-2xl p-2 pb-3 colorHomeText">
							{preSpanText}{' '}
							<span className="colorDealText">
								{spanText}
							</span>{' '}
							{postSpanText}
						</h1>
						<h2 className="text-2xl p-2 py-8 colorHomeText">
							At MYDealTeams, we’ve developed a tool that
							addresses retail and commercial Real Estate
							location management needs and more. Our
							platform is designed specifically for retail
							real estate professionals, offering:
						</h2>
					</div>
				</div>
			</div>
			<FeaturesDirect />
		</Layout>
	)
}

export default FeaturesPanelWrap
