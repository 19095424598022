import React, { useEffect, useRef } from 'react'
import { motion } from 'motion/react'
import ImageA from '../assets/image-folder.png'
import ImageB from '../assets/notes.png'
import ImageC from '../assets/tasks.png'
import ImageD from '../assets/homepage.png'
import CTAButton from './CTAButton'
import SecondaryImage from '../assets/Blend-2.png'

const SuccessPanel = () => {
	const containerRef = useRef(null)

	const items = [
		'Manage all your real estate projects with a robust workflow and task management system.',
		'Interact with your teams and partners with Chat and Email integration.',
		'Share notes and observations, and upload andshare documents, images, and videos from multiple sources.',
	]
	const listVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.25,
			},
		},
	}

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add('animate')
					} else {
						entry.target.classList.remove('animate')
					}
				})
			},
			{ threshold: 0.1 }
		)

		const container = containerRef.current
		if (container) {
			observer.observe(container)
		}

		return () => {
			if (container) {
				observer.unobserve(container)
			}
		}
	}, [])

	return (
		<div className=" max-w-[1240px] mx-auto grid md:grid-cols-2 gap-x-8 mb-32 ">
			<div
				id="image-collage"
				className="relative w-[500px] mx-auto"
				ref={containerRef}
			>
				<motion.div
					id="image-folder"
					initial={{
						x: '-50px',
						y: '-50px',
						scale: 0.8,
						opacity: 0,
					}}
					whileInView={{
						opacity: 1,
						scale: 1,
						x: -20,
						y: 0,
						transition: {
							duration: 1.0,
						},
					}}
					viewport={{
						once: true,
						amount: 0.8,
					}}
				>
					<img
						className="absolute w-[225px] border shadow-md shadow-[#0080fe]"
						src={ImageA}
						alt="A"
					/>
				</motion.div>
				<motion.div
					id="notes"
					initial={{
						x: 310,
						y: -56,
						scale: 0.8,
						opacity: 0,
					}}
					whileInView={{
						opacity: 1,
						scale: 1,
						x: 260,
						y: -6,
						transition: {
							duration: 1.0,
						},
					}}
					viewport={{
						once: true,
						amount: 0.8,
					}}
				>
					<img
						className="absolute w-[284px] border shadow-md shadow-[#0080fe] "
						src={ImageB}
						alt="B"
					/>
				</motion.div>

				<motion.div
					id="homepage"
					initial={{
						x: 165,
						y: 140,
						opacity: 0,
					}}
					whileInView={{
						opacity: 1,
						scale: 1,
						x: 115,
						y: 90,
						transition: {
							duration: 1.0,
						},
					}}
					viewport={{
						once: true,
						amount: 0.8,
					}}
				>
					<img
						className="absolute w-[382px] border shadow-md shadow-[#0080fe] "
						src={ImageD}
						alt="D"
					/>
				</motion.div>
				<motion.div
					id="tasks"
					initial={{
						x: -50,
						y: 230,
						scale: 0.8,
						opacity: 0,
					}}
					whileInView={{
						opacity: 1,
						scale: 1,
						x: 0,
						y: 180,
						transition: {
							duration: 1.0,
						},
					}}
					viewport={{
						once: true,
						amount: 0.8,
					}}
				>
					<img
						className="absolute w-[193px] border shadow-md shadow-[#0080fe] "
						src={ImageC}
						alt="C"
					/>
				</motion.div>
			</div>
			<div className="w-[580px] flex flex-col justify-center md:ml-8">
				<h1
					id="success-banner"
					className="md:text-4xl sm:text-3xl text-2xl text-[#074f9b]"
				>
					Your Gateway to{' '}
					<span className=" text-[#25A4FC]">Deal Team </span>{' '}
					Success.
				</h1>
				<div
					id="success-list"
					className=" mt-4 md:text-2xl sm:text-xl text-lg text-[#052852] pl-6"
				>
					<motion.ul
						initial="hidden"
						whileInView="visible"
						viewport={{
							once: true,
							amount: 0.8,
						}}
						variants={listVariants}
						className="list-disc p-2"
					>
						{items.map((item, index) => (
							<motion.li
								key={index}
								variants={itemVariants}
								className="mb-2"
							>
								{item}
							</motion.li>
						))}
					</motion.ul>
				</div>
				<h1 className="text-center md:text-2xl sm:text-xl text-lg p-2 text-[#25A4FC]">
					Affordable, Streamlined, Comprehensive.
				</h1>
				<CTAButton actionPhrase="Join Our Upcoming Beta" />
			</div>
		</div>
	)
}

export default SuccessPanel
