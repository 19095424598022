import React, { useEffect, useState } from 'react'
import CTAButton from '../CTAButton'

const CardPricing = ({
	cardImage,
	cardHeader,
	cardPrice,
	cardList,
	cardButton,
	cardSlide,
}) => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	return (
		<div className="w-[300px] border shadow-xl flex flex-col rounded-lg hover:scale-105 duration-100 ease-in-out">
			<h2 className="text-xl font-bold text-center my-3">
				{cardHeader}
			</h2>
			<p className="text-center text-3xl font-bold py-1">
				{cardPrice}
			</p>
			<div className="flex-grow flex items-start justify-center">
				<ul className="list-disc">
					{cardList.map((listItem, index) => (
						<li className="text-sm" key={index}>
							{listItem}
						</li>
					))}
				</ul>
			</div>
			<div className="flex justify-center">
				<CTAButton actionPhrase="Get In Touch" />
			</div>
		</div>
	)
}

export default CardPricing
