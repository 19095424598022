import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const CTAButton = ({ actionPhrase }) => {
	return (
		<div>
			<div className="flex justify-center">
				<Link to="/contact">
					<button className="bg-[#0080fe] text-white  rounded-md font-medium my-6 mx-auto md:mx-8 py-3 hover:scale-110 duration-150">
						<span className="pl-8 pr-8">{actionPhrase}</span>
					</button>
				</Link>
			</div>
		</div>
	)
}

export default CTAButton
