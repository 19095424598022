import React from 'react'
import { motion } from 'motion/react'
import PrimaryImage from '../assets/Blend-2.png'
import CTAButton from './CTAButton'

const CommingSoon = () => {
	return (
		<div className="w-full h-full pb-[64px]">
			<div>
				<div className="max-w-[1240px] w-full mx-auto text-center flex flex-col justify-center">
					<h1 className="md:text-4xl sm:text-3xl text-2xl pb-4 colorHomeText">
						Coming Soon
					</h1>
					<div className="py-1 font-semibold text-lg">
						<p className="colorHomeText">
							The MYDealTeams beta launch is coming soon,
							and we're looking for industry professionals
							who would like to help test this exciting new
							platform.
						</p>
						{/* </div>
					<div className="py-1 font-semibold text-lg"> */}
						<p className="colorHomeText">
							If you're looking to take your location and
							deal management process to the next level,
							you'll love what you see here!
						</p>
					</div>
				</div>
				<motion.div
					initial={{ scale: 0.9, opacity: 0 }}
					whileInView={{
						opacity: 1,
						scale: 1,
						transition: { duration: 1 },
					}}
					viewport={{
						once: true,
						amount: 0.25,
					}}
				>
					<img
						className="lg:max-w-[1100px] md:max-w-[750px] sm:max-w-[500px] max-w-[300px] mx-auto mt-4 my-2"
						src={PrimaryImage}
						alt="/"
					/>
				</motion.div>
				<CTAButton actionPhrase="Join Our Upcoming Beta" />
			</div>
		</div>
	)
}

export default CommingSoon
