import React from 'react'
import { motion } from 'motion/react'
import PrimaryImage from '../assets/MDT-Home.png'

const Home = () => {
	// useEffect(() => {
	// 	window.scrollTo(0, 0)
	// }, [])

	return (
		<div className="w-full md:h-screen py-2 px-4">
			<div className=" mx-auto mt-2 ">
				<div
					className={`flex flex-col justify-center md:ml-16 col-span-2 
					`}
				>
					<motion.div
						initial={{ scale: 0.9, opacity: 0 }}
						whileInView={{
							opacity: 1,
							scale: 1,
							transition: { duration: 1.5 },
						}}
						viewport={{
							once: true,
							amount: 0.8,
						}}
					>
						<img
							className="w-[1000px] mx-auto my-4"
							src={PrimaryImage}
							alt="/"
						/>
					</motion.div>
				</div>
				<div
					className={`flex flex-col col-span-1 text-center justify-center md:ml-16 `}
				>
					<motion.div
						className="flex items-center justify-center mt-2"
						initial={{ scale: 0.9, opacity: 0 }}
						whileInView={{
							opacity: 1,
							scale: 1,
							transition: { duration: 1.5 },
						}}
						viewport={{
							once: true,
							amount: 0.8,
						}}
					>
						<p className=" colorHomeText md:text-4xl sm:text-3xl text-xl sm:mb-16 ">
							Retail Location Management{' '}
							<span className="colorDealText">
								{' '}
								Simplified.
							</span>
						</p>
					</motion.div>
				</div>
			</div>
		</div>
	)
}

export default Home
