import React from 'react'
import { motion } from 'motion/react'
import IconRing from '../assets/icon-ring.png'
import styled from 'styled-components'
// import PrimaryImage from '../assets/locations-home.png'

const FeaturePage = ({ featureData, featureImage, featureLink }) => {
	const primaryImage = featureData.primaryImage

	return (
		<React.Fragment>
			<StyledDiv>
				<motion.div
					className={`max-w-[1300px] mx-auto border shadow-xl flex flex-col p-1 mb-16 rounded-lg hover:scale-105 duration-100 ease-in-out`}
					initial={{ scale: 0.9, opacity: 0 }}
					whileInView={{
						opacity: 1,
						scale: 1,
						transition: { duration: 1.0 },
					}}
					viewport={{
						once: true,
						amount: 0.5,
					}}
				>
					<div className="grid md:grid-cols-7 items-center ">
						<motion.div
							className="flex flex-col justify-center lg:col-span-3 md:ml-2"
							initial={{ scale: 0.9, opacity: 0 }}
							whileInView={{
								opacity: 1,
								scale: 1,
								transition: { duration: 1.0 },
							}}
							viewport={{
								once: true,
								amount: 0.6,
							}}
						>
							<div>
								<h1 className="md:text-4xl sm:text-3xl text-2xl font-normal pb-8 text-center colorMDTText">
									{featureData.banner}
								</h1>
							</div>
							<div className="relative mx-auto pb-4 flex justify-center items-center w-48 h-48">
								<img
									className="absolute inset-0 w-full h-full object-cover"
									src={IconRing}
									alt=""
								/>
								<div className="pt-4">
									<img
										className="relative  w-36 h-36 object-cover fa-icon"
										src={featureImage}
										alt="Feature Image"
									/>
								</div>
							</div>
							<div>
								<p className="md:text-2xl sm:text-2xl text-xl text-center font-sans colorHomeText pt-4">
									{featureData.featurePhrase}
								</p>
								<div className="text-center">
									<ul className="colorHomeText text-lg">
										{featureData.featureList.map(
											(item, index) => (
												<li key={index}>
													{item.listItem}
												</li>
											)
										)}
									</ul>
								</div>
							</div>
						</motion.div>
						<motion.div
							className="flex flex-col justify-center items-center lg:col-span-4 md:ml-2 pb-8"
							initial={{ scale: 0.9, opacity: 0 }}
							whileInView={{
								opacity: 1,
								scale: 1,
								transition: { duration: 1.0 },
							}}
							viewport={{
								once: true,
								amount: 0.6,
							}}
						>
							<img
								src={primaryImage}
								alt="Primary Image"
								className=" mx-auto my-6 rounded-lg shadow-md  shadow-[#0080fe] h-[400px]"
							/>
						</motion.div>
					</div>
				</motion.div>
			</StyledDiv>
		</React.Fragment>
	)
}

const StyledDiv = styled.div`
	.icon-container {
		display: flex;
		flex-wrap: wrap;
	}

	.icon-item {
		flex: 1 1 50%; /* Two columns */
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 16px;
	}

	.fa-icon {
		width: 96px;
		height: 96px;
		/* margin-bottom: 8px; */
		color: #0080fe;
	}

	.nav-link-label {
		font-size: 1.5rem;
		/* font-weight: 500; */
		text-align: center;
		color: #000333;
	}
`

export default FeaturePage
