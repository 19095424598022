import React, { useEffect, useState } from 'react'
import { motion } from 'motion/react'
import PrimaryImage from '../assets/solution.png'

import {
	FaAppStore,
	FaFileAlt,
	FaFolderOpen,
	FaMapMarkerAlt,
	FaProjectDiagram,
	FaUsers,
} from 'react-icons/fa'
import { BsFillChatLeftTextFill } from 'react-icons/bs'
import styled from 'styled-components'

const Solution = () => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<StyledDiv>
				<div className="w-full max-w-[1240px] mx-auto pt-2 pb-16">
					<div>
						<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl colorHomeText">
							We Know What You Want
						</h1>
					</div>
					{/* <div className="max-w-[1240px] mx-auto pt-12 grid "> */}
					<motion.div
						className="w-full mx-auto pt-16"
						initial={{ scale: 0.98, opacity: 0 }}
						whileInView={{
							opacity: 1,
							scale: 1,
							transition: { duration: 1 },
						}}
						viewport={{
							once: true,
							amount: 0.8,
						}}
					>
						<img
							className="mx-auto rounded-lg"
							src={PrimaryImage}
							alt="/"
						/>
					</motion.div>
					{/* </div> */}
				</div>
			</StyledDiv>
		</div>
	)
}

const StyledDiv = styled.div`
	.fa-icon {
		width: 48px;
		height: 48px;
		/* padding-right: 10px; */
		margin-bottom: 8px;
		color: #0080fe;
	}

	.fa-icon-label {
		font-size: 2rem;
		margin-left: 18px;
		/* font-weight: 500; */
		color: #0080fe;
	}
`

export default Solution
