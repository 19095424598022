import React from 'react'
import './styles/global.css' // Import the global CSS file
import HomeWrap from './wrappers/HomeWrap'
import Layout from './components/Layout'

function App() {
	return (
		<Layout>
			<HomeWrap />
		</Layout>
	)
}

export default App
