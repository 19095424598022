import React from 'react'
import Layout from '../components/Layout'
import Solution from '../components/Solution'
import FeaturesAll from '../components/FeaturesAll'

const SolutionWrap = () => {
	return (
		<Layout>
			<div className="w-full px-4 py-2 mb-16">
				<Solution />
			</div>
			<FeaturesAll />
		</Layout>
	)
}

export default SolutionWrap
