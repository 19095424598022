import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

//Navbar imports
import SolutionWrap from './wrappers/SolutionWrap'
import FeaturesWrap from './wrappers/FeaturesWrap'
import AboutWrap from './wrappers/AboutWrap'
import PricingWrap from './wrappers/PricingWrap'
import ContactWrap from './wrappers/ContactWrap'

//Click Panel imports
import FeaturesPanelWrap from './wrappers/FeaturesPanelWrap'
import PrivacyPolicy from './wrappers/PrivacyPolicy'

// import LearnWrap from './wrappers/LearnWrap'
// import DemoWrap from './wrappers/DemoWrap'
// import LocationsWrap from './wrappers/LocationsWrap'
// import ProjectsWrap from './wrappers/ProjectsWrap'
// import PartnersWrap from './wrappers/PartnersWrap'
// import ChatsWrap from './wrappers/ChatsWrap'
// import FilesWrap from './wrappers/FilesWrap'
// import FutureWrap from './wrappers/FutureWrap'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
	//Navbar routes
	{ path: '/', element: <App /> },
	{ path: '/solution', element: <SolutionWrap /> },
	{ path: '/features', element: <FeaturesWrap /> },
	{ path: '/about', element: <AboutWrap /> },
	{ path: '/pricing', element: <PricingWrap /> },
	{ path: '/contact', element: <ContactWrap /> },

	//Click Panel routes
	{ path: '/feature', element: <FeaturesPanelWrap /> },
	// { path: '/learn', element: <LearnWrap /> },
	// { path: '/demo', element: <DemoWrap /> },
	// { path: '/locations', element: <LocationsWrap /> },
	// { path: '/projects', element: <ProjectsWrap /> },
	// { path: '/partners', element: <PartnersWrap /> },
	// { path: '/chats', element: <ChatsWrap /> },
	// { path: '/files', element: <FilesWrap /> },
	// { path: '/future', element: <FutureWrap /> },
	{ path: '/privacypolicy', element: <PrivacyPolicy /> },
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
