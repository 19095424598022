import React from 'react'
import Navbar from '../components/Navbar'
import Home from '../components/Home'
import SuccessPanel from '../components/SuccessPanel'
import FeaturesPanel from '../components/FeaturesPanel'
import ComingSoon from '../components/ComingSoon'
import Footer from '../components/Footer'

function App() {
	return (
		<div>
			<Home />
			<SuccessPanel />
			<FeaturesPanel />
			<ComingSoon />
		</div>
	)
}

export default App
