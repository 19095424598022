import React from 'react'
import PrimaryImage from '../assets/hand-on-laptop.jpg'
import Layout from '../components/Layout'
import About from '../components/About'

const AboutWrap = () => {
	return (
		<Layout>
			<div
				className="features-background"
				style={{ '--background-image': `url(${PrimaryImage})` }}
			>
				<About />
			</div>
		</Layout>
	)
}

export default AboutWrap
