import React from 'react'
import Layout from '../components/Layout'
import Pricing from '../components/Pricing'

const PricingWrap = () => {
	return (
		<Layout>
			<Pricing />
		</Layout>
	)
}

export default PricingWrap
