import emailjs from '@emailjs/browser'

const userId = process.env.REACT_APP_EMAILJS_USER_ID
const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID

const emailInviteNewUser = (fromUser, toUser) => {
	const templateId = 'template_zuins8b'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		firstName: toUser.firstName,
		lastName: toUser.lastName,
		toName: toUser.toName,
		toEmail: toUser.email,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then((res) => {
			console.log(`Email Welcome was Successfull: `, res)
		})
		.catch((err) => console.log(`Send error: `, err))
}

const emailInviteNewUsercc = (fromUser, toUser) => {
	const templateId = 'template_68eqifa'

	console.log(`toUser: `, toUser)
	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		firstName: toUser.firstName,
		lastName: toUser.lastName,
		companyName: toUser.companyName,
		toName: toUser.toName,
		toEmail: toUser.email,
		phoneNumber: toUser.phoneNumber,
		message: toUser.message,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then((res) => {
			console.log(`Email CC Successfull: `, res)
		})
		.catch((err) => console.log(`Send error: `, err))
}

export { emailInviteNewUser, emailInviteNewUsercc }
