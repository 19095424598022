import React from 'react'
import { useLocation } from 'react-router-dom'
import FeaturePage from './FeaturePage'
import featuresData from '../data/FeaturesData.json'
import * as Icons from 'react-icons/fa'
import { BsFillChatLeftTextFill } from 'react-icons/bs'
import { renderToStaticMarkup } from 'react-dom/server'

const FeaturesAll = () => {
	const features = featuresData.Features
	const location = useLocation()
	const { featureId } = location.state || {}

	const feature = featuresData.Features.find((f) => f._id === featureId)
	console.log('FeatureAll - feature:', feature)

	const convertIconToDataURL = (IconComponent) => {
		const svgString = renderToStaticMarkup(
			<IconComponent style={{ fill: '#0080fe' }} />
		)
		const encodedSvgString = encodeURIComponent(svgString)
		return `data:image/svg+xml,${encodedSvgString}`
	}

	return (
		<div className="w-full ">
			{features.map((feature, index) => {
				const IconComponent =
					Icons[feature.featureIcon] || BsFillChatLeftTextFill
				const iconUrl = convertIconToDataURL(IconComponent)

				return (
					<div key={index} id={feature._id}>
						<FeaturePage
							featureData={feature}
							featureImage={iconUrl}
							featureLink={`/features#${feature._id}`}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default FeaturesAll
