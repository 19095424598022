import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Layout = ({ children }) => {
	return (
		<div id="Layout">
			<Navbar />
			<main className="main-content">{children}</main>
			<div>
				<Footer />
			</div>
		</div>
	)
}

export default Layout
