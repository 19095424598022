import React from 'react'
import { Link } from 'react-router-dom'
import IconRing from '../../assets/icon-ring.png'
import styled from 'styled-components'

const CardFeatures = ({ cardBanner, cardImage, cardLink }) => {
	return (
		<React.Fragment>
			<StyledDiv>
				<div className="w-full border shadow-md shadow-[#0080fe] flex flex-col p-4 my-4 rounded-2xl hover:scale-105 duration-100">
					<Link to={cardLink}>
						<div className="relative mx-auto pb-4 flex justify-center items-center w-48 h-48">
							<img
								className="absolute inset-0 w-full h-full object-cover"
								src={IconRing}
								alt=""
							/>
							<div className="pt-4">
								<img
									className="relative  w-36 h-36 object-cover fa-icon"
									src={cardImage}
									alt="Card Image"
								/>
							</div>
						</div>

						<h2 className="text-[#052852] text-2xl font-semibold text-center py-3">
							{cardBanner}
						</h2>
					</Link>
				</div>
			</StyledDiv>
		</React.Fragment>
	)
}

const StyledDiv = styled.div`
	.fa-icon {
		width: 96px;
		height: 96px;
		/* margin-bottom: 8px; */
		color: #0080fe;
	}
`

export default CardFeatures
