import React from 'react'
import { useLocation } from 'react-router-dom'
import FeaturePage from './FeaturePage'
import featuresData from '../data/FeaturesData.json'
import * as Icons from 'react-icons/fa'
import { BsFillChatLeftTextFill } from 'react-icons/bs'
import { renderToStaticMarkup } from 'react-dom/server'

const FeaturesDirect = () => {
	const features = featuresData.Features

	const location = useLocation()
	const featureHash = location.hash || {}
	const featureId = featureHash.substring(1)
	const feature = featuresData.Features.find((f) => f._id === featureId)

	const convertIconToDataURL = (IconComponent) => {
		const svgString = renderToStaticMarkup(
			<IconComponent style={{ fill: '#0080fe' }} />
		)
		const encodedSvgString = encodeURIComponent(svgString)
		return `data:image/svg+xml,${encodedSvgString}`
	}
	const IconComponent = Icons[feature.featureIcon] || BsFillChatLeftTextFill
	const iconUrl = convertIconToDataURL(IconComponent)

	return (
		<div className="w-full ">
			<FeaturePage
				featureData={feature}
				featureImage={iconUrl}
				featureLink={`/features#${feature._id}`}
			/>
		</div>
	)
}

export default FeaturesDirect
