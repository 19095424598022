import React, { useEffect, useRef, useState } from 'react'
import LeftImage from '../assets/holding-laptop.png'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import PopupThankYou from './PopupThankYou'
import {
	emailInviteNewUser,
	emailInviteNewUsercc,
} from '../functions/emailOptions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

const Contact = () => {
	const navigate = useNavigate()

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [message, setMessage] = useState('')
	const [showPopup, setShowPopup] = useState(false)
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const stripFormatting = (phone) => {
		return phone.replace(/[^\d]/g, '')
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		// Handle form submission logic here
		const fromUser = {
			name: 'MYDealTeams',
			email: 'mikeoc@mydealteams.com',
		}

		const toUser = {
			firstName: firstName,
			lastName: lastName,
			toName: `${firstName} ${lastName}`,
			companyName: companyName,
			email: email,
			phoneNumber: stripFormatting(phoneNumber),
			message: message,
		}

		// Send email to the provided email address
		emailInviteNewUser(fromUser, toUser)

		// Send email to info@mydealteams.com
		emailInviteNewUsercc(fromUser, toUser)
		setShowPopup(true)
	}

	const handleClosePopup = () => {
		setShowPopup(false)
		navigate('/') // Redirect to Home page
	}

	return (
		<div className="w-full h-[71.2vh]  py-2">
			<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl colorHomeText ">
				{/* <span className="colorDealText">Affordable</span> , */}
				We'd Love To Hear From You.
			</h1>
			<div className="max-w-[1240px] mx-auto grid md:grid-cols-2 pt-6">
				<div
					className={`bg-sky-200  ${
						animate ? 'slide-in-left' : ''
					}`}
				>
					<img
						src={LeftImage}
						alt="Left"
						className="h-[580px] max-w-auto pt-2"
					/>
				</div>
				<div
					className={`bg-[#25A4FC] ${
						animate ? 'slide-in-right' : ''
					}`}
				>
					<form className="p-8 " onSubmit={handleSubmit}>
						<h1 className="text-white text-4xl font-semibold mb-6">
							{' '}
							Get In Touch
						</h1>
						<div className="mb-4">
							<input
								type="firstName"
								id="firstName"
								className="w-full py-2 px-3 rounded-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								value={firstName}
								onChange={(e) =>
									setFirstName(e.target.value)
								}
								placeholder="First Name"
								required
							/>
						</div>
						<div className="mb-4">
							<input
								type="lastName"
								id="lastName"
								className="w-full py-2 px-3 rounded-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								value={lastName}
								onChange={(e) =>
									setLastName(e.target.value)
								}
								placeholder="Last Name"
								required
							/>
						</div>
						<div className="grid md:grid-cols-2 gap-4">
							<div className="mb-4">
								<input
									type="email"
									id="email"
									className="w-full py-2 px-3 rounded-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={email}
									onChange={(e) =>
										setEmail(e.target.value)
									}
									required
									placeholder="Email"
								/>
							</div>
							<div className="mb-4">
								<InputMask
									mask="(999) 999-9999"
									value={phoneNumber}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									placeholder="Phone Number"
									required
								/>
							</div>
						</div>
						<div className="mb-4">
							<input
								type="companyName"
								id="companyName"
								className="w-full py-2 px-3 rounded-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								value={companyName}
								onChange={(e) =>
									setCompanyName(e.target.value)
								}
								required
								placeholder="Company Name"
							/>
						</div>
						<div className="mb-4">
							<textarea
								id="message"
								className="w-full py-2 px-3 rounded-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								value={message}
								onChange={(e) =>
									setMessage(e.target.value)
								}
								rows="4"
								placeholder="Message"
							></textarea>
						</div>
						<div className="grid md:grid-cols-2 gap-4">
							<div className="flex justify-start">
								<button
									type="submit"
									className="w-[100px] rounded-sm bg-sky-700 text-white font-semibold px-4 py-2 "
								>
									Send
								</button>
							</div>
							<div className="flex justify-end">
								<p className="pt-1 text-white">
									<FontAwesomeIcon
										icon={faEnvelope}
									/>{' '}
									info@mydealteams.com
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
			{showPopup && <PopupThankYou onClose={handleClosePopup} />}
		</div>
	)
}

export default Contact
