import React from 'react'
import { Link } from 'react-router-dom'
import CTAButton from './CTAButton'

const Footer = () => {
	return (
		<footer className="w-full p-1 pb-6 bg-[#074f9b] text-white">
			<div className="mx-auto grid lg:grid-cols-7 gap-8">
				<div className=" lg:col-span-4 flex text-center flex-col justify-center">
					<p className="mt-4 pl-2 text-3xl">
						A winning combination of collaboration and site
						visibility.
					</p>
					<div className="flex justify-center mt-6">
						<p className="max-w-[800px] text-s">
							We care about the protection and privacy of
							your data. Read our{' '}
							<span className="mb-8 text-[#0080fe]">
								<Link
									className="underline underline-offset-4"
									to="/privacypolicy"
								>
									Privacy Policy
								</Link>
							</span>{' '}
						</p>
					</div>
					{/* <p className="mt-2 pl-2 pt-2text-xl">
						Bring your partners, teams, and resources for deal
						execution together in one fun new space.
					</p> */}
					{/* <p className="pl-2">together in one fun new space.</p> */}
				</div>
				<div className="flex flex-col justify-center ">
					<p className="text-start text-lg mb-1 ">Pages</p>
					<div className="grid grid-cols-2 text-start text-sm">
						<Link to="/" className=" hover:font-semibold">
							Home
						</Link>
						<Link
							to="/solution"
							className="pl-1 hover:font-semibold"
						>
							Solution
						</Link>
						<Link
							to="/features"
							className=" hover:font-semibold"
						>
							Features
						</Link>
						<Link
							to="/about"
							className="pl-1 hover:font-semibold"
						>
							About
						</Link>
						<Link
							to="/pricing"
							className="hover:font-semibold"
						>
							Pricing
						</Link>
						<Link
							to="/contact"
							className="pl-1 hover:font-semibold"
						>
							Contact
						</Link>
					</div>
				</div>
				<div className="flex flex-col justify-center lg:col-span-2">
					<h1 className="flex justify-center mt-6 text-2xl">
						We'd love to hear from you.
					</h1>
					<div className="flex justify-center">
						<CTAButton actionPhrase="Get In Touch" />
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
