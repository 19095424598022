import React, { useEffect, useState } from 'react'
import { motion } from 'motion/react'
import CardPricing from './card/CardPricing'
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'

const Pricing = () => {
	return (
		<div className="w-full py-2">
			<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl colorHomeText ">
				<span className="colorDealText">Affordable</span> ,
				Streamlined, Comprehensive.
			</h1>
			<div id="pricing-cards">
				<motion.div
					initial={{ scale: 0.9, opacity: 0 }}
					whileInView={{
						opacity: 1,
						scale: 1,
						transition: { duration: 1 },
					}}
					viewport={{
						once: true,
						// amount: 0.8,
					}}
					className="max-w-[1000px] mx-auto my-24 grid md:grid-cols-3 gap-16"
				>
					<CardPricing
						cardImage={Single}
						cardHeader="Community User"
						cardPrice="Free"
						cardList={[
							'Join Existing Projects',
							'Add and modify Project Tasks',
							'Add Notes and Initiate Chats',
							'Add Images',
							'Partner with existing Users',
							'Join Existing Portfolios',
						]}
						cardButton="Contact Us"
						// cardSlide="slide-in-left"
					/>
					<CardPricing
						cardImage={Double}
						cardHeader="Pro User"
						cardPrice="$69"
						cardList={[
							'Create and Load New Locations',
							'Create Projects',
							'Add and modify Project Tasks',
							'Add Notes and Initiate Chats',
							'Add Images',
							'Upload Documents',
							'Build Task Templates',
							'Build Site Detail Templates',
							'Partner with existing Users',
							'Invite New Users',
							'Build Portfolios',
						]}
						cardButton="Contact Us"
						// cardSlide="slide-in-bottom"
					/>
					<CardPricing
						cardImage={Triple}
						cardHeader="Administrator"
						cardPrice="$89"
						cardList={['Under Construction']}
						cardButton="Contact Us"
						// cardSlide="slide-in-right"
					/>
				</motion.div>
			</div>
		</div>
	)
}

export default Pricing
