import React, { useEffect, useState } from 'react'
import PrimaryImage from '../assets/man-at-sign.png'

const About = () => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<div className="w-full h-full  py-2">
				<div className="">
					<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl colorHomeText ">
						Welcome to <span className="colorMyText">MY</span>
						<span className="colorDealText">Deal</span>
						<span className="colorTeamsText">Teams</span>
					</h1>
					<div className="max-w-[1000px] mx-auto grid md:grid-cols-2 mt-12 gap-24">
						<div className={`flex justify-end `}>
							<div className="">
								<p
									className={`w-full uppercase text-2xl mb-1 font-normal colorMDTText ${
										animate ? 'slide-in-left' : ''
									}`}
								>
									The Vision Behind MYDealTeams{' '}
								</p>
								<p
									className={`w-full text-2xl max-w-[400px] ${
										animate ? 'slide-in-left' : ''
									}`}
								>
									We've sat in your seat, navigating
									the challenges of managing
									locations, tasks, and
									communications across deal teams.
									We've experienced the frustrations
									of juggling multiple tools,
									scattered information, and siloed
									team collaboration.
								</p>
							</div>
						</div>
						<div className="flex justify-start">
							<div
								className={`${
									animate ? 'slide-in-right' : ''
								}`}
							>
								<p className="w-full uppercase text-2xl mb-1 font-normal colorMDTText">
									We Understand you.
								</p>
								<p className="w-full text-2xl mb-8 ">
									At{' '}
									<span className="colorMyText">
										MY
									</span>
									<span className="colorDealText">
										Deal
									</span>
									<span className="colorTeamsText">
										Teams
									</span>
									, we understand the complexities of
									retail real estate because we've
									been in your shoes. Founded on
									nearly two decades of hands-on
									experience supporting retail real
									estate deals, we've made it our
									mission to simplify and streamline
									the deal making process for
									professionals like you.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					className={`flex pt-10 pb-20 justify-start ${
						animate ? 'slide-up' : ''
					}`}
				>
					<div className="max-w-[1240px] mx-auto ">
						<p className="w-full uppercase text-2xl ml-8 max-w-[1200px] colorMDTText">
							What we’ve know about your business and the
							challenges you face:{' '}
						</p>
						<div className="w-full text-2xl ml-8 max-w-[800px] ">
							<ul className="list-disc p-2 ml-20 colorMyText">
								<li className="mb-2">
									<span className="font-semibold ">
										Comprehensive Location
										Management:{' '}
									</span>
									<span className="colorTeamsText">
										You need a solution that lets
										you oversee all your locations
										in one place.
									</span>
								</li>
								<li className="mb-2">
									<span className="font-semibold">
										Efficient Project & Task
										Tracking:{' '}
									</span>
									<span className="colorTeamsText">
										Whether it’s new leases,
										remodels, or new store
										openings, managing projects
										and tasks effectively is
										critical.{' '}
									</span>
								</li>
								<li className="mb-2">
									<span className="font-semibold">
										Seamless Team Communication:{' '}
									</span>
									<span className="colorTeamsText">
										Keeping everyone—from internal
										stakeholders to external
										partners—on the same page is
										essential, and you need a
										system that retains those
										conversations for future
										reference.{' '}
									</span>
								</li>
								<li className="mb-2">
									<span className="font-semibold">
										Centralized Access for Deal
										Teams:{' '}
									</span>
									<span className="colorTeamsText">
										All team members should have
										access to documents, projects,
										task assignments, and
										communications—without jumping
										between systems.{' '}
									</span>
								</li>
								<li className="mb-2">
									<span className="font-semibold">
										Integrated Tools &
										Applications:{' '}
									</span>
									<span className="colorTeamsText">
										Your tools should work
										seamlessly together, not
										create more work for your
										team.{' '}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
